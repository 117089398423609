const OrganizationTypes = {
    GET_ORGANIZATION_DATA_START: "get_organization_data_start",
    GET_ORGANIZATION_DATA_SUCCESS: "get_organization_data_success",
    GET_ORGANIZATION_DATA_FAILURE: "get_organization_data_failure",
    CREATE_ORGANIZATION_START: "create_organization_start",
    CREATE_ORGANIZATION_SUCCESS: "create_organization_success",
    CREATE_ORGANIZATION_FAILURE: "create_organization_failure" ,
    CREATE_TEAM_START: "create_team_start",
    CREATE_TEAM_SUCCESS: "create_team_success",
    CREATE_TEAM_FAILURE: "create_team_failure" ,
    GET_COLOR_PALETTE_START: "get_color_palette_start",
    GET_COLOR_PALETTE_SUCCESS: "get_color_palette_success",
    GET_COLOR_PALETTE_FAILURE: "get_color_palette_failure" ,
    GET_ORG_TEAMS_START: "get_org_teams_start",
    GET_ORG_TEAMS_SUCCESS: "get_org_teams_success",
    GET_ORG_TEAMS_FAILURE: "get_org_teams_failure",
    UPDATE_TEAM_DETAILS_START: "update_team_details_start",
    UPDATE_TEAM_DETAILS_SUCCESS: "update_team_details_success",
    UPDATE_TEAM_DETAILS_FAILURE: "update_team_details_failure",
    GET_USER_ROLES_START: "get_user_roles_start",
    GET_USER_ROLES_SUCCESS: "get_user_roles_success",
    GET_USER_ROLES_FAILURE: "get_user_roles_failure",
    ADD_EMPLOYEES_START: "add_employees_start",
    ADD_EMPLOYEES_SUCCESS: "add_employees_success",
    ADD_EMPLOYEES_FAILURE: "add_employees_failure",
    GET_ORG_EMPLOYEES_START: "get_org_employees_start",
    GET_ORG_EMPLOYEES_SUCCESS: "get_org_employees_success",
    GET_ORG_EMPLOYEES_FAILURE: "get_org_employees_failure",
    GET_ORG_INVITED_EMPLOYEES_START: "get_org_invited_employees_start",
    GET_ORG_INVITED_EMPLOYEES_SUCCESS: "get_org_invited_employees_success",
    GET_ORG_INVITED_EMPLOYEES_FAILURE: "get_org_invited_employees_failure",
    
    UPDATE_ORGANISATION_START: "update_organisation_start",
    UPDATE_ORGANISATION_SUCCESS: "update_organisation_success",
    UPDATE_ORGANISATION_FAILURE: "update_organisation_failure",
    REMOVE_USER_START: "remove_user_start",
    REMOVE_USER_SUCCESS: "remove_user_success",
    REMOVE_USER_FAILURE: "remove_user_failure",
    UPDATE_USER_START: "update_user_start",
    UPDATE_USER_SUCCESS: "update_user_success",
    UPDATE_USER_FAILURE: "update_user_failure",

    GET_TEAMS_LIST_START: "get_teams_list_start",
    GET_TEAMS_LIST_SUCCESS: "get_teams_list_success",
    GET_TEAMS_LIST_FAILURE: "get_teams_list_failure",

    GET_CALL_LOGS_START: "get_call_logs_start",
    GET_CALL_LOGS_SUCCESS: "get_call_logs_success",
    GET_CALL_LOGS_FAILURE: "get_call_logs_failure",

    GET_CALL_OVERVIEW_START: "GET_CALL_OVERVIEW_START",
    GET_CALL_OVERVIEW_SUCCESS: "GET_CALL_OVERVIEW_SUCCESS",
    GET_CALL_OVERVIEW_FAILURE: "GET_CALL_OVERVIEW_FAILURE",

    GET_EMPLOYEE_CALL_LOGS_START: "GET_EMPLOYEE_CALL_LOGS_START",
    GET_EMPLOYEE_CALL_LOGS_SUCCESS: "GET_EMPLOYEE_CALL_LOGS_SUCCESS",
    GET_EMPLOYEE_CALL_LOGS_FAILURE: "GET_EMPLOYEE_CALL_LOGS_FAILURE",
    GET_EMPLOYEE_CALL_LOGS_RESET: "GET_EMPLOYEE_CALL_LOGS_RESET",

    GET_ORG_EMPLOYEES_LIST_START: "GET_ORG_EMPLOYEES_LIST_START",
    GET_ORG_EMPLOYEES_LIST_SUCCESS: "GET_ORG_EMPLOYEES_LIST_SUCCESS",
    GET_ORG_EMPLOYEES_LIST_FAILURE: "GET_ORG_EMPLOYEES_LIST_FAILURE",

    GET_USER_PLAN_LIST_START: "GET_USER_PLAN_LIST_START",
    GET_USER_PLAN_LIST_SUCCESS: "GET_USER_PLAN_LIST_SUCCESS",
    GET_USER_PLAN_LIST_FAILURE: "GET_USER_PLAN_LIST_FAILURE",

    GET_USER_CREDIT_HISTORY_START: "GET_USER_CREDIT_HISTORY_START",
    GET_USER_CREDIT_HISTORY_SUCCESS: "GET_USER_CREDIT_HISTORY_SUCCESS",
    GET_USER_CREDIT_HISTORY_FAILURE: "GET_USER_CREDIT_HISTORY_FAILURE",

    GET_CUSTOM_EMPLOYEES_START: "GET_CUSTOM_EMPLOYEES_START",
    GET_CUSTOM_EMPLOYEES_SUCCESS: "GET_CUSTOM_EMPLOYEES_SUCCESS",
    GET_CUSTOM_EMPLOYEES_FAILURE: "GET_CUSTOM_EMPLOYEES_FAILURE",

    GET_CURRENT_ACTIVE_SEATS_START: "GET_CURRENT_ACTIVE_SEATS_START",
    GET_CURRENT_ACTIVE_SEATS_SUCCESS: "GET_CURRENT_ACTIVE_SEATS_SUCCESS",
    GET_CURRENT_ACTIVE_SEATS_FAILURE: "GET_CURRENT_ACTIVE_SEATS_FAILURE",

    GET_CURRENT_SUBSCRIBED_SEATS_START: "GET_CURRENT_SUBSCRIBED_SEATS_START",
    GET_CURRENT_SUBSCRIBED_SEATS_SUCCESS: "GET_CURRENT_SUBSCRIBED_SEATS_SUCCESS",
    GET_CURRENT_SUBSCRIBED_SEATS_FAILURE: "GET_CURRENT_SUBSCRIBED_SEATS_FAILURE",



    GET_USER_ALL_ORGANIZATIONS_START: "GET_USER_ALL_ORGANIZATIONS_START",
    GET_USER_ALL_ORGANIZATIONS_SUCCESS: "GET_USER_ALL_ORGANIZATIONS_SUCCESS",
    GET_USER_ALL_ORGANIZATIONS_FAILURE: "GET_USER_ALL_ORGANIZATIONS_FAILURE",

    FETCH_USER_INTEGRATIONS: "FETCH_USER_INTEGRATIONS",
    FETCH_USER_INTEGRATIONS_SUCCESS: "FETCH_USER_INTEGRATIONS_SUCCESS",
    FETCH_USER_INTEGRATIONS_FAILURE: "FETCH_USER_INTEGRATIONS_FAILURE",

    GET_ORG_CONVERSATION_ANALYTICS_START : "GET_ORG_CONVERSATION_ANALYTICS_START",
    GET_ORG_CONVERSATION_ANALYTICS_SUCCESS : "GET_ORG_CONVERSATION_ANALYTICS_SUCCESS",
    GET_ORG_CONVERSATION_ANALYTICS_FAILURE : "GET_ORG_CONVERSATION_ANALYTICS_FAILURE",

    
    GET_ORG_QUICK_REPLIES_ANALYTICS_START: "GET_ORG_QUICK_REPLIES_ANALYTICS_START",
    GET_ORG_QUICK_REPLIES_ANALYTICS_SUCCESS: "GET_ORG_QUICK_REPLIES_ANALYTICS_SUCCESS",
    GET_ORG_QUICK_REPLIES_ANALYTICS_FAILURE: "GET_ORG_QUICK_REPLIES_ANALYTICS_FAILURE",

    GET_ORG_SCHEDULER_ANALYTICS_START: "GET_ORG_SCHEDULER_ANALYTICS_START",
    GET_ORG_SCHEDULER_ANALYTICS_SUCCESS: "GET_ORG_SCHEDULER_ANALYTICS_SUCCESS",
    GET_ORG_SCHEDULER_ANALYTICS_FAILURE: "GET_ORG_SCHEDULER_ANALYTICS_FAILURE",

    GET_INTEGRATIONS_LAST_SYNC_START: "GET_INTEGRATIONS_LAST_SYNC_START",
    GET_INTEGRATIONS_LAST_SYNC_SUCCESS: "GET_INTEGRATIONS_LAST_SYNC_SUCCESS",
    GET_INTEGRATIONS_LAST_SYNC_FAILURE: "GET_INTEGRATIONS_LAST_SYNC_FAILURE",

    SAVE_DONT_SHOW_UPGRADE : "SAVE_DONT_SHOW_UPGRADE",
    GET_ALL_USER_PROFILE_DATA: "GET_ALL_USER_PROFILE_DATA",
    TOGGLE_ADD_EMPLOYEE_MODAL: "TOGGLE_ADD_EMPLOYEE_MODAL"

} 

export default OrganizationTypes;