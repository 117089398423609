import { getUserAccessToken } from "./helper";

export const APP_URL_PREFIX = "https://eazybe.com/api/v1/whatzapp/"
export const APP_URL_PREFIX_V2 = "https://api.eazybe.com/v2/"

export const HUBSPOT_URL_PREFIX = "hubspot/";


export const AWS_CHATS_URL = "https://search-amplify-opense-2nk1c3rosgqd-fwfa3nvjugcrfz2qhknxo2fune.ap-south-1.es.amazonaws.com"

export  const AWS_CALLS_URL = "https://search-amplify-opense-2nk1c3rosgqd-fwfa3nvjugcrfz2qhknxo2fune.ap-south-1.es.amazonaws.com"


export const EXTENSION_ID_PRODUCTION = "clgficggccelgifppbcaepjdkklfcefd";  ///Live
// export const EXTENSION_ID_PRODUCTION = "baclcomamlkhdlkadpnbaiflpepbbghl";/// Sumit
// export const EXTENSION_ID_PRODUCTION = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";  ///Rajat

export const EXTENSION_ID_TEST = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";


export const API = {
    GET_CALL_LOGS: "getcallyzerlogs",
    GET_USER_DETAILS: 'registercallyzeruser',
    GET_ORGANISATION_CALL_LOGS: 'myemployees',
    GET_ORGANIZATION_DATA: 'getOrganizationDetails',
    CREATE_ORGANIZATION: 'createCallyzerOrganization',
    CREATE_TEAM: 'cretenewteam',
    GET_COLOR_PALETTE: 'getcolorpalet',
    GET_ORG_TEAMS_ONLY: 'getallteams',
    GET_ORG_TEAMS: 'getcompleteteamdetails',
    UPDATE_TEAM_DETAILS: 'updateteamdetails',
    GET_USER_ROLES: 'getmasterroles',
    ADD_EMPLOYEES: 'adduserdetails',
    GET_EMPLOYEES: 'getmyemployeelist',
    GET_ORG_EMPLOYEES: 'myemployeelist',
    CHECK_USER_EXISTENCE: 'checkuserexistance',
    USER_CREDIT_HISTORY: 'usercredithistory',
    ORG_CREDIT_HISTORY: 'remainseatsavablity',
    UPDATE_ORGANISATION: 'updateorganizationdetails',
    REMOVE_USER: 'cancelrequest',
    UPDATE_EMPLOYEE: 'updateuserdetails',
    ADD_EMPLOYEES_TO_TEAM: 'updateuserteam',
    GET_TEAMS_LIST: 'allteamdeails',
    GET_TOP_PERFORMER: 'topperformer',
    GET_NEED_ATTENTION: 'needattention',
    GET_FILTERED_CALL_LOGS: 'getfilteredcalllogs',
    GET_CALLS_OVERVIEW: 'getallusercalloverview',
    GET_UNIQUE_CLIENT: 'findUniqndtotal',
    GET_ACTIVE_HOURS: 'activehoursbycalls',
    GET_USER_FOLLOWUPS: 'myfollowups',
    CREATE_TAGS: 'createorgtags',
    GET_ORG_TAGS: 'getorganizationtags',
    SAVE_TAG_VALUES: 'organizationtagvalue',
    SAVE_FOLLOWUP: 'saveorgfollowup',
    CREATE_QUICK_REPLY: 'createquickreply',
    GET_QUICK_REPLIES: 'getquickreplies',
    QUICK_REPLIES_ANALYTICS: 'quickrepliesalanytics',
    UPDATE_QUICK_REPLY: 'updatecommonquickreply',
    DELETE_QUICK_REPLY: 'deletecommonquickreply',
    SAVE_COMMENT: 'saveorgfollowupusercomments',
    GET_FOLLOWUP_WITH_COMMENTS: 'followupdetailsbyid',
    GET_SPLINE_CHART: 'getsplinchartdata',
    GET_UNIQUE_SPLINE_CHART: 'getuniquesplinedata',
    GET_FOLLOWUP_DETAIL_WITH_COMMENTS: 'followupdetailsbyid',
    SAVE_FOLLOWUP_NOTES: 'saveorgfollowupcomments',
    UPDATE_ORG_TAGS: 'updateorgtag',
    UPDATE_ORG_CONFIG: 'updateorgconfiguration',
    GET_USER_TAGS: 'getallcolumns',
    CALL_THROUGH_AWS: 'callthroughaws',
    GET_USER_PLAN_LIST: 'getuserplanlist',
    GET_USER_CREDIT_HISTORY: 'getuserorgplandetails',
    GET_USER_SYNC_REPORT: 'getyncitems',
    GET_SPLINE_CHART_START_QUICK_REPLY: 'getquickreplysplinchartdata',
    GET_QUICK_REPLIES_TABULAR_DATA: 'getquickreplytabulardata',
    GET_ALL_ORGANIZATION_LIST: "getallorglist",
    SET_ACTIVE_ORGANIZATION: "updatedefaultorganization",
    CHANGE_USER_SYNC_STATUS: "addusertosyncworkspace",
    GET_USER_FOLLOWUP_DATA: "followupuserdata",
    EDIT_DELETE_FOLLOWUP_DATA: "editcommonfollowupnote",
    CREATE_FOLLOW_UP_DATA: "createcommonfollowup",
    PLAN_LIST: "planlist",
    USER_PROFILE_DATA: "userprofiledata",
    USER_TAGS: "getusertags",
    GET_TEAM_ADDON_LIST: "getteamaddonlist",
    GET_ALLOTED_LABELS: "getallotedlabels",
    GET_USER_NOTES: "getusernotes",
    CREATE_USER_NOTE: "createnote",
    UPDATE_USER_NOTE: "updateusernote",
    GET_CONVERSATION_ANALYTICS: "getconversationanalytics",
    GET_DATE_WISE_CONVERSATION_ANALYTICS: "getdatewiseconversationanalytics",
    GET_DATE_WISE_NEEDS_REPLY_AWATING_REPLY: "getdatewiseneedsreplyawaitingreplydata",
    READ_FROM_DRIVE: "readfromdrive",
    GOOGLE_DRIVE_DATA: "googledrivedata",
    FETCH_USER_INTEGRATIONS: "fetchuserintegrations",
    UPDATE_USER_EMAIL: "updateuseremail",
    GET_SCHEDULER_ANALYTICS : "getcustomerschedulecount",
    GET_INTEGRATIONS_LAST_SYNC : "getintegrationslastsync",
    GET_ZOHO_LEADS_FIELD_DATA : '/zoho/lead/fields',
    GET_ZOHO_CONTACTS_FIELD_DATA : '/zoho/contact/fields',
  CHECK_MOBILE_NUMBER_EXISTENCE : "checkmobilenumberexistance",
    GET_CRM_USER_PROPERTIES : 
  'crm/userproperties',
  GET_USER_DATA_V2 : "auth/accounts",
  CREATE_SALESFORCE_AUTH : "salesforce/auth"
}

export const FETCH = ({ url, method, body, ContentType }) => {
    return fetch(APP_URL_PREFIX + url, {
      method: method ? method : 'GET',
      headers: {
        'Content-Type': ContentType ? ContentType : 'application/json, text/plain, */*',
      },
      body: body
    })
  
  }

  export const FETCH2 = ({ url, method, body, ContentType, accessToken }) => {
    return fetch(APP_URL_PREFIX_V2 + url, {
      method: method ? method : 'GET',
      headers: {
        'Content-Type': ContentType ? ContentType : 'application/json, text/plain, */*',
      },
      body: body
    })
  }

  export const FETCHV2 = async ({ url, method, body, ContentType, Authorization }) => {
    const authToken = getUserAccessToken()
   
    return fetch(APP_URL_PREFIX_V2 + url, {
      method: method ? method : "GET",
      headers: {
        // "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": authToken ? `Bearer ${authToken}` : ""
      },
      body: body,
    });
  };
  

  export const SIDENAVLAYOUTTYPES = Object.freeze({
    SECTION_EAZYBE: "SECTION_EAZYBE",
    SECTION_EAZYBE_USER_PROFILE: "SECTION_EAZYBE_USER_PROFILE",
    SECTION_EAZYBE_TASK: "SECTION_EAZYBE_TASK",
    SECTION_EAZYBE_DOCS: "SECTION_EAZYBE_DOCS",
    SECTION_EAZYBE_FOLLOWUP: "SECTION_EAZYBE_FOLLOWUP",
    SECTION_EAZYBE_LOADER: "SECTION_EAZYBE_LOADER",
    SECTION_INTEGRATIONS: "SECTION_INTEGRATIONS",
    SECTION_INTEGRATIONS_MAIN: "SECTION_INTEGRATIONS_MAIN",
    SECTION_INTEGRATIONS_HUBSPOT: "SECTION_INTEGRATIONS_HUBSPOT",
    SECTION_INTEGRATIONS_HUBSPOT_PROFILE: "SECTION_INTEGRATIONS_HUBSPOTPROFILE",
    SECTION_INTEGRATIONS_HUBSPOT_TASK: "SECTION_INTEGRATIONS_HUBSPOT_TASK",
    SECTION_INTEGRATIONS_HUBSPOT_FOLLOWUP:
      "SECTION_INTEGRATIONS_HUBSPOT_FOLLOWUP",
    SECTION_INTEGRATIONS_HUBSPOT_DEALS: "SECTION_INTEGRATIONS_HUBSPOT_DEALS",
    SECTION_INTEGRATIONS_HUBSPOT_TICKETS: "SECTION_INTEGRATIONS_HUBSPOT_TICKETS",
    SECTION_MISC: "SECTION_MISC",
    SECTION_TEST: "SECTION_TEST",
    SECTION_EAZYBE_USER_ACCOUNT_SECTION: "SECTION_EAZYBE_USER_ACCOUNT_SECTION",
    SECTION_INTEGRATIONS_GOOGLE_SHEET: "SECTION_INTEGRATIONS_GOOGLE_SHEET",
    SECTION_INTEGRATIONS_GOOGLE_SHEET_PROFILE:
      "SECTION_INTEGRATIONS_GOOGLE_SHEET_PROFILE",
    SECTION_INTEGRATIONS_GOOGLE_SHEET_TASK:
      "SECTION_INTEGRATIONS_GOOGLE_SHEET_TASK",
    SECTION_INTEGRATIONS_GOOGLE_SHEET_FOLLOWUP:
      "SECTION_INTEGRATIONS_GOOGLE_SHEET_FOLLOWUP",
    SECTION_INTEGRATIONS_BITRIX: "SECTION_INTEGRATIONS_BITRIX",
    SECTION_INTEGRATIONS_BITRIX_PROFILE: "SECTION_INTEGRATIONS_BITRIX_PROFILE",
    SECTION_INTEGRATIONS_BITRIX_TASK: "SECTION_INTEGRATIONS_BITRIX_TASK",
    SECTION_INTEGRATIONS_BITRIX_FOLLOWUP: "SECTION_INTEGRATIONS_BITRIX_FOLLOWUP",
    SECTION_INTEGRATIONS_FRESHDESK: "SECTION_INTEGRATIONS_FRESHDESK",
    SECTION_INTEGRATIONS_FRESHDESK_PROFILE:
      "SECTION_INTEGRATIONS_FRESHDESK_PROFILE",
    SECTION_INTEGRATIONS_FRESHDESK_FOLLOWUP:
      "SECTION_INTEGRATIONS_FRESHDESK_FOLLOWUP",
    SECTION_INTEGRATIONS_FRESHDESK_TASK: "SECTION_INTEGRATIONS_FRESHDESK_TASK",
    SECTION_INTEGRATIONS_FRESHDESK_TICKETS:
      "SECTION_INTEGRATIONS_FRESHDESK_TICKETS",
    SECTION_EAZYBE_CHANGE_NAME: "SECTION_EAZYBE_CHANGE_NAME",
    SECTION_INTEGRATIONS_FRESHDESK_TICKETS:
      "SECTION_INTEGRATIONS_FRESHDESK_TICKETS",
    SECTION_INTEGRATIONS_ZOHO: "SECTION_INTEGRATIONS_ZOHO",
    SECTION_EAZYBE_MENTIONED_GROUP_CHAT_SECTION: "SECTION_EAZYBE_MENTIONED_GROUP_CHAT_SECTION",
    SECTION_EAZYBE_USER_SETTINGS: "SECTION_EAZYBE_USER_SETTINGS",
    SECTION_INTEGRATIONS_HUBSPOT_CALL_LOGS : "SECTION_INTEGRATIONS_HUBSPOT_CALL_LOGS",
  })
